var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unplatfollow-wrapper bj" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "seach order-wrapper" },
          [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("创建日期：")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    align: "right",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.changeCreateDate },
                  model: {
                    value: _vm.createDate,
                    callback: function ($$v) {
                      _vm.createDate = $$v
                    },
                    expression: "createDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("完成日期：")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    align: "right",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.changeFinishDate },
                  model: {
                    value: _vm.finishDate,
                    callback: function ($$v) {
                      _vm.finishDate = $$v
                    },
                    expression: "finishDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("支付日期：")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    align: "right",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.changePayDate },
                  model: {
                    value: _vm.payDate,
                    callback: function ($$v) {
                      _vm.payDate = $$v
                    },
                    expression: "payDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("供货日期：")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    align: "right",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.changeSupplierDate },
                  model: {
                    value: _vm.supplierDate,
                    callback: function ($$v) {
                      _vm.supplierDate = $$v
                    },
                    expression: "supplierDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入订单号", clearable: "" },
                  model: {
                    value: _vm.formData.order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "order_no", $$v)
                    },
                    expression: "formData.order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入售后单号", clearable: "" },
                  model: {
                    value: _vm.formData.as_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "as_no", $$v)
                    },
                    expression: "formData.as_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入客户电话", clearable: "" },
                  model: {
                    value: _vm.formData.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "mobile", $$v)
                    },
                    expression: "formData.mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入商品名称", clearable: "" },
                  model: {
                    value: _vm.formData.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入货号", clearable: "" },
                  model: {
                    value: _vm.formData.sku_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "sku_code", $$v)
                    },
                    expression: "formData.sku_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style",
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择二级分类",
                    },
                    model: {
                      value: _vm.formData.cid,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "cid", $$v)
                      },
                      expression: "formData.cid",
                    },
                  },
                  _vm._l(_vm.subCidList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style",
                    attrs: { clearable: "", placeholder: "请选择售后原因" },
                    model: {
                      value: _vm.formData.reason_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "reason_type", $$v)
                      },
                      expression: "formData.reason_type",
                    },
                  },
                  _vm._l(_vm.reasonList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style",
                    attrs: { clearable: "", placeholder: "请选择集配中心" },
                    model: {
                      value: _vm.formData.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "logistic_business_id", $$v)
                      },
                      expression: "formData.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.businessList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style",
                    attrs: {
                      placeholder: "选择城市仓",
                      "filter-method": (val) =>
                        _vm.onFilterMethod("cityStore", val),
                      filterable: "",
                      clearable: "",
                    },
                    on: {
                      change: _vm.onChangeCityStore,
                      "visible-change": (val) =>
                        _vm.onChangeVisible("cityStore", val),
                    },
                    model: {
                      value: _vm.formData.logistics_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "logistics_id", $$v)
                      },
                      expression: "formData.logistics_id",
                    },
                  },
                  _vm._l(_vm.cityStoreList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style",
                    attrs: {
                      placeholder: "选择自提点",
                      "filter-method": (val) =>
                        _vm.onFilterMethod("selfTake", val),
                      filterable: "",
                      clearable: "",
                    },
                    on: {
                      "visible-change": (val) =>
                        _vm.onChangeVisible("selfTake", val),
                    },
                    model: {
                      value: _vm.formData.delivery_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "delivery_id", $$v)
                      },
                      expression: "formData.delivery_id",
                    },
                  },
                  _vm._l(_vm.selfTakeList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入业务员姓名", clearable: "" },
                  model: {
                    value: _vm.formData.buyer_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "buyer_name", $$v)
                    },
                    expression: "formData.buyer_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入供应商名称", clearable: "" },
                  model: {
                    value: _vm.formData.supplier_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "supplier_name", $$v)
                    },
                    expression: "formData.supplier_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "jn-input-style multiple-select-long",
                    attrs: {
                      placeholder: "选择售后状态",
                      "collapse-tags": "",
                      filterable: "",
                      multiple: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.process_list,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "process_list", $$v)
                      },
                      expression: "formData.process_list",
                    },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "el-select-dropdown__item",
                        class: {
                          selected:
                            _vm.formData.process_list.length ===
                            _vm.stateList.length,
                        },
                        on: { click: _vm.onHandleSelectedAll },
                      },
                      [_c("span", [_vm._v("全选")])]
                    ),
                    _vm._l(_vm.stateList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  staticClass: "jn-input-style",
                  attrs: { placeholder: "请输入供货单号", clearable: "" },
                  model: {
                    value: _vm.formData.supplier_order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "supplier_order_no", $$v)
                    },
                    expression: "formData.supplier_order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-autocomplete", {
                  staticClass: "jn-input-style",
                  attrs: {
                    "fetch-suggestions": _vm.querySearchAsync,
                    placeholder: "请输入组名",
                  },
                  model: {
                    value: _vm.formData.team_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "team_name", $$v)
                    },
                    expression: "formData.team_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onHandleSearch },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  disabled: !_vm.tableData.length || _vm.disabledExport,
                  type: "warning",
                  icon: "el-icon-download",
                },
                on: { click: _vm.onHandleExport },
              },
              [_vm._v("导出")]
            ),
            _c("el-button", {
              staticClass: "bjsearch",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "logistic_business_name",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                  align: "center",
                  label: "集配中心",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.logistic_business_name || "-")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                  align: "center",
                  label: "供货商",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getSupplierName(scope.row.aftersale))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "fullname", align: "center", label: "业务员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.getSupplierBuyerName(scope.row.aftersale)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logisticsName",
                  align: "center",
                  label: "城市仓",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getLogisticsName(scope.row.aftersale))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryName",
                  width: "150",
                  "show-overflow-tooltip": "",
                  align: "center",
                  label: "自提点",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getDeliveryName(scope.row.aftersale))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "clientele_services_team_name",
                  width: "180",
                  align: "center",
                  label: "组名",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memberName",
                  width: "180",
                  align: "center",
                  label: "订单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (scope.row.aftersale &&
                                scope.row.aftersale.order_no) ||
                                "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "order_no", align: "center", label: "客户名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getMemberName(scope.row.aftersale)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memberMobile",
                  width: "150",
                  align: "center",
                  label: "客户电话",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getMemberMobile(scope.row.aftersale)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "as_no",
                  width: "180",
                  align: "center",
                  label: "售后单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (scope.row.aftersale &&
                                scope.row.aftersale.as_no) ||
                                "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  width: "150",
                  label: "创建时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.create_time || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "supplier_create_at",
                  align: "center",
                  width: "150",
                  label: "供货日期",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsName",
                  align: "center",
                  width: "150",
                  label: "商品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getGoodsName(scope.row.aftersale)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sku_code",
                  align: "center",
                  width: "150",
                  label: "货号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cate_name",
                  align: "center",
                  width: "150",
                  label: "二级分类",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reason_type",
                  align: "center",
                  width: "150",
                  label: "售后原因",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getReason(scope.row.aftersale))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "process_name",
                  align: "center",
                  width: "150",
                  label: "售后状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.process_name || "-")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": _vm.is_force_intervene ? 220 : 150,
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: { "justify-content": "space-around" },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "main-color pointer text-none",
                                staticStyle: { "white-space": "nowrap" },
                                attrs: {
                                  to: {
                                    name: "newAfterDetail",
                                    query: {
                                      id: scope.row.as_id,
                                      state: scope.row.state,
                                    },
                                  },
                                },
                              },
                              [_vm._v("查看详情 ")]
                            ),
                            _vm.is_force_intervene
                              ? [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.aftersale.state === 0,
                                          expression:
                                            "scope.row.aftersale.state === 0",
                                        },
                                      ],
                                      staticClass: "main-color pointer",
                                      staticStyle: { "white-space": "nowrap" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogFormVisible = true
                                          _vm.as_id = scope.row.as_id
                                        },
                                      },
                                    },
                                    [_vm._v(" 强制介入 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.aftersale.state > 0,
                                          expression:
                                            "scope.row.aftersale.state > 0",
                                        },
                                      ],
                                      staticClass: "main-color",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        color: "rgba(0, 0, 0, 0.3)",
                                      },
                                    },
                                    [_vm._v(" 强制介入 ")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "强制介入",
                visible: _vm.dialogFormVisible,
                width: "60%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents1" },
                [
                  _c("div", { staticClass: "mb-10 font-weight" }, [
                    _vm._v(
                      " 此售后将转到平台介入中，请输入介入原因，此原因城市仓、供货商可查看 "
                    ),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 10 },
                      placeholder: "填写介入原因",
                    },
                    model: {
                      value: _vm.refuseReason,
                      callback: function ($$v) {
                        _vm.refuseReason = $$v
                      },
                      expression: "refuseReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                          _vm.refuseReason = ""
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitFrom },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }